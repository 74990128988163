// 使用 import 语句引入图标文件
// https://static.ecipc.cn/icon.png
function setFavicon(iconUrl) {
    let link = document.querySelector("link[rel*='icon']");

    if (!link) {
        link = document.createElement('link');
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = iconUrl;
}

window.addEventListener('DOMContentLoaded', () => {
    setFavicon('https://static.ecipc.cn/icon.png');
});

//js动态设置浏览器标签icon https://static.ecipc.cn/icon.png

